import React, { useState } from 'react'
import Layout from '../components/Layout'
import axios from 'axios'
import SEO from '../components/Seo'
import { celularMask } from '../lib/mask.js'

// Converse conosco => (054) 9 9706-4410
// Peça seu orçamento => email

const Contato = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [cidadeEstado, setCidadeEstado] = useState('')
  const [celular, setCelular] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [successMail, setSuccessMail] = useState(false)

  const onChangeName = e => {
    setName(e.target.value)
  }
  const onChangeEmail = e => {
    setEmail(e.target.value)
  }
  const onChangeCidadeEstado = e => {
    setCidadeEstado(e.target.value)
  }
  const onChangeCelular = e => {
    setCelular(celularMask(e.target.value))
  }
  const onChangeMensagem = e => {
    setMensagem(e.target.value)
  }

  const [nameDefault, setNameDefault] = useState('hidden ')
  const [emailDefault, setEmailDefault] = useState('hidden ')
  const [cidadeEstadoDefault, setCidadeEstadoDefault] = useState('hidden ')
  const [celularDefault, setCelularDefault] = useState('hidden ')
  const [mensagemDefault, setMensagemDefault] = useState('hidden ')

  const [errorMessageName, setErrorMessageName] = useState('')
  const [errorMessageEmail, setErrorMessageEmail] = useState('')
  const [errorMessageCidadeEstado, setErrorMessageCidadeEstado] = useState('')
  const [errorMessageCelular, setErrorMessageCelular] = useState('')
  const [errorMessageMensagem, setErrorMessageMensagem] = useState('')

  const validateEmail = email => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const verifyErrors = () => {
    setNameDefault('hidden ')
    setEmailDefault('hidden ')
    setCidadeEstadoDefault('hidden ')
    setCelularDefault('hidden ')
    setMensagemDefault('hidden ')
    let noErrors=true
    if (name === '') {
      noErrors=false
      setErrorMessageName('Nome é um campo obrigatório.')
      setNameDefault('')
    } else if (!name.includes(' ') || name.split(' ')[1] === '') {
      noErrors=false
      setErrorMessageName('Nome inválido.')
      setNameDefault('')
    }
    if (email === '') {
      noErrors=false
      setErrorMessageEmail('E-mail é um campo obrigatório.')
      setEmailDefault('')
    } else if (!validateEmail(email)) {
      noErrors=false
      setErrorMessageEmail('E-mail inválido.')
      setEmailDefault('')
    }
    if (cidadeEstado === '') {
      noErrors=false
      setErrorMessageCidadeEstado('Cidade/Estado é um campo obrigatório.')
      setCidadeEstadoDefault('')
    } else if (!cidadeEstado.includes('/') || cidadeEstado.split('/')[1].length < 2) {
      noErrors=false
      setErrorMessageCidadeEstado('Cidade/Estado inválidos.')
      setCidadeEstadoDefault('')
    }
    if (celular === '') {
      noErrors=false
      setErrorMessageCelular('Celular é um campo obrigatório.')
      setCelularDefault('')
    } else if (celular.length < 15) {
      noErrors=false
      setErrorMessageCelular('Número de celular inválido.')
      setCelularDefault('')
    }
    if (mensagem === '') {
      noErrors=false
      setErrorMessageMensagem('Mensagem é um campo obrigatório.')
      setMensagemDefault('')
    }
    return noErrors
  }

  const handleSubmit = () => {
    if (verifyErrors()){
      send()
    }
  }

  const send = async() => {
    await axios.post('/.netlify/functions/send', {
      from: 'contato',
      name,
      email,
      cidadeEstado,
      celular,
      mensagem
    })
    setSuccessMail(true)
    console.log('email enviado')
    smoothScrollBackToTop()
  }

  function smoothScrollBackToTop() {
    const targetPosition = 0;
    const startPosition = (typeof window !== `undefined`) ? window.pageYOffset : 0;
    const distance = targetPosition - startPosition;
    const duration = 300;
    let start = null;

    if (typeof window !== `undefined`) window.requestAnimationFrame(step)

    function step(timestamp) {
      if (!start) start = timestamp
      const progress = timestamp - start
      if (typeof window !== `undefined`) window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration))
      if (progress < duration)
        if (typeof window !== `undefined`) window.requestAnimationFrame(step)
    }
    return true
  }

  function easeInOutCubic(t, b, c, d) {
    t /= d/2
    if (t < 1) return c/2*t*t*t + b
    t -= 2
    return c/2*(t*t*t + 2) + b
  }

  return (
    <Layout>
      <SEO description='Entre em contato conosco através de nossos canais de atendimento.' />
      <h1 className='invisible'>Contato</h1>
      {
        // Mensagem de sucesso de envio do formulário
        successMail &&
        <div className="bg-teal-100 border-t-4 border-teal-500 text-teal-900 px-4 py-3 shadow-md" role="alert">
          <div className="flex">
            <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
            <div>
              <p className="font-bold">Sua mensagem foi enviada com sucesso!</p>
              <p className="text-sm">Em breve entraremos em contato com você e responderemos suas dúvidas.</p>
            </div>
          </div>
        </div>
      }
      {
        // Formulário de contato
        !successMail &&
        <div className='w-11/12 mx-auto mt-2 md:w-4/5 text-bg-gray-900'>
          <p className='flex w-full sm:w-4/5 lg:w-2/3 robika text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl underlin block uppercase tracking-wide mb-6 md:mt-10 font-bold' htmlFor='grid-title'>
          Entre em contato conosco pelo formulário
          </p>
          <div className='bg-white shadow-md  px-8 pt-6 pb-8 mb-4'>
            <div className='flex flex-wrap -mx-3 mb-4'>
              <div className='w-full md:w-1/2 mb-4 md:mb-0 md:px-3'>
                <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-first-name'>
                  Nome Completo
                </label>
                <input onChange={onChangeName} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-first-name' type='text' placeholder='Insira seu nome aqui.' />
                <p className={nameDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageName}</p>
              </div>
              <div className='w-full md:w-1/2 md:mb-0 md:px-3'>
                <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-email'>
                  E-mail
                </label>
                <input onChange={onChangeEmail} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-email' type='text' placeholder='Insira seu email aqui.' />
                <p className={emailDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageEmail}</p>
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-4'>
              <div className='w-full md:w-1/2 mb-4 md:mb-0 md:px-3'>
                <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-city'>
                  Cidade/Estado
                </label>
                <input onChange={onChangeCidadeEstado} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-city' type='text' placeholder='Insira sua Cidade/UF aqui.' />
                <p className={cidadeEstadoDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageCidadeEstado}</p>
              </div>
              <div className='w-full md:w-1/2 mb-4 md:mb-0 md:px-3'>
                <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-zip'>
                  Celular
                </label>
                <input onChange={onChangeCelular} value={celular} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' id='grid-zip' type='text' placeholder='(DDD) Número' />
                <p className={celularDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageCelular}</p>
              </div>
            </div>
            <div className='flex flex-wrap -mx-3 mb-4'>
              <div className='w-full md:mb-0 md:px-3'>
                <label className='px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' htmlFor='grid-message'>
                  Mensagem
                </label>
                <textarea onChange={onChangeMensagem} className='appearance-none block w-full shadow text-gray-700 border border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:border-gray-500' rows='5' type='text' placeholder='Digite sua mensagem aqui.' />
                <p className={mensagemDefault + 'pl-2 text-red-500 text-xs italic'}>{errorMessageMensagem}</p>
              </div>
            </div>
            <div className='flex items-center -mx-3 mt-6 justify-center'>
              <div className=''>
                <button className='bg-gray-900 hover:bg-gray-800 focus:shadow-outline focus:outline-none text-base text-white font-bold py-2 px-10 md:px-32 ' onClick={handleSubmit}>
                  ENVIAR
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

export default Contato